<template>
  <div style="width:100%">
  <v-dialog v-model="dialogo" fullscreen persistent transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar dark :color="colores.secundario">
        <v-btn icon dark @click="cerrarVenta"><v-icon>mdi-close</v-icon></v-btn>
        <v-toolbar-title>CREAR PQR</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-card-text>
            <horaComponent :tokenTiempo="tokenInbound" @eventotiempo="tiempo_pqr"></horaComponent>
          </v-card-text>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="pt-4">
        <formComponent :tokenInbound="tokenInbound" :loadingData="loadingData" :items="items" :colores="colores" :session="session"></formComponent>
      </v-card-text>
    </v-card>
  </v-dialog>
  </div>
</template>

<script>
// import horaComponent from '@/components/widgets/horaComponent.vue'
// import formComponent from '@/components/crm/formComponent.vue'
export default {
  name: 'crm_dialogoComponent',
  components: {
    horaComponent: () => import('@/components/widgets/horaComponent.vue'),
    formComponent: () => import('@/components/crm/formComponent.vue')
  },
  props: ['colores', 'tokenInbound', 'dialogo', 'items', 'loadingData', 'session'],
  data: () => ({
    tiempo: 0
  }),
  methods: {
    cerrarVenta () {
      this.$emit('closed_venta')
    },
    tiempo_pqr (ptiempo) {
      this.tiempo = ptiempo
    }
  }
}
</script>
